<template>
  <div>
    <template>
      <v-container fluid grid-list-xl style="height:100%">
          <v-layout row wrap>
              <v-flex xs12>
                  <accounts></accounts>
              </v-flex>
              <v-flex xs12 sm6 md4>
                  <widget v-bind:type="'income'"></widget>
              </v-flex>
              <v-flex xs12 sm6 md4>
                  <widget v-bind:type="'expense'"></widget>
              </v-flex>
              <v-flex xs12 sm6 md4>
                  <widget v-bind:type="'budget'"></widget>
              </v-flex>
          </v-layout>
      </v-container>
    </template>

  </div>
</template>

<script>

  export default {
    components: {
        widget: () => import('../../components/widgets/widget.vue'),
        accounts: () => import('../../components/widgets/accountWidget.vue')
    }

  }
</script>
